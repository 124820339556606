import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Modal } from 'react-bootstrap';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

import toaster from '../utils/toaster';
import LoadingElement from './LoadingElement';

// API constants
const apiName = 'supersightApiMvp';
const measurementPath = '/measurement';

function MonitoringModal({ phoneId, phoneName, dateStart, dateEnd, handleClose }) {
  const [monitoringData, setMonitoringData] = useState(null);
  const [charts, setCharts] = useState(null);

  // These are the labels of the monitoring data that will be shown in the charts
  const monitoringDataLabels = [
    "screenTime",
    "batteryLevel",
    "batteryTemperature",
    "fps"
  ]

  // Get monitoring data when the modal is shown
  useEffect(() => {
    const fetchMonitoringData = async () => {
      try {
        const getResponse = await API.get(apiName, `${measurementPath}/${phoneId}?dateStart=${dateStart}&dateEnd=${dateEnd}`, {});

        if (!getResponse.success) {
          throw new Error(getResponse.error);
        }

        getResponse.data.forEach(dataPoint => {
          if (dataPoint.fps) dataPoint.fps /= 100;
        })

        setMonitoringData(getResponse.data);
      } catch (error) {
        toaster('Error fetching monitoring data.', 2, error);
        setMonitoringData(undefined);
      }
    }

    fetchMonitoringData();
  }, []);

  useEffect(() => {
    if (monitoringData === null || monitoringData === undefined || monitoringData.length === 0 || charts !== null) return;

    try {
      // Assign color to line based on battery status data
      const lineColor = monitoringData.map(measurement => {
        if (measurement.batteryStatus === -1) {
          return 'yellow';
        } else if (measurement.batteryStatus === 0) {
          return 'red';
        } else {
          return 'green';
        }
      });

      // Chart properties
      const yAxis = {
        screenTime: undefined,
        batteryLevel: {
          suggestedMin: 0,
          suggestedMax: 100
        },
        batteryTemperature: {
          suggestedMin: -10,
          suggestedMax: 60
        },
        fps: {
          suggestedMin: 0,
          suggestedMax: 40
        }
      }
      const titles = {
        screenTime: 'Screen time',
        batteryLevel: 'Battery level',
        batteryTemperature: 'Battery temperature',
        fps: 'FPS'
      }

      // Get timestamps
      const timestamps = monitoringData.map(m => new Date(m.dateCreated));
      
      // Function to convert time elapsed in milliseconds into a string
      const timeElapsed = (time) => {
        let timeString = "";

        // 24 h/d * 3600000 millisecs/h = 86400000 millisecs/d
        if (Math.floor((time) / 86400000) > 0) {
          timeString += `${Math.floor((time) / 86400000)}d `;
          time = time % 86400000;
        }

        if (Math.floor((time) / 3600000) > 0) {
          timeString += `${Math.floor((time) / 3600000)}h `;
          time = time % 3600000;
        }

        timeString += `${Math.floor((time) / 60000)}m `;
        return timeString;
      }

      // Create charts
      const allCharts = monitoringDataLabels.map(label => {
        const options = {
          responsive: true,
          scales: {
            x: {
              type: 'time',
              time: {
                displayFormats: {
                  hour: 'MMM D, YYYY, HH:00' // Format including date and hour
                }
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: titles[label]
              }
            },
          },
          plugins: {
            title: {
              display: true,
              text: titles[label],
            },
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: function(context) {
                  // Display the label as the tooltip title
                  return timestamps[context.dataIndex];
                },
                label: function(context) {
                  const dataPoint = monitoringData[context.dataIndex];
  
                  // Screen time info
                  const screenTime = `Screen time: ${timeElapsed(dataPoint.screenTime)}`;
  
                  // Battery info
                  const batteryLevel = `Battery level: ${dataPoint.batteryLevel}%`;
                  const batteryTemperature = `Battery temperature: ${dataPoint.batteryTemperature}°C`;
                  const batteryStatus = `Battery status: ${
                    dataPoint.batteryStatus === -1 ? "Unknown" :
                    dataPoint.batteryStatus === 0 ? "Discharging" : "Charging" 
                  }`;

                  // FPS
                  const fps = `FPS: ${dataPoint.fps}`;
  
                  // Combine info above into the result
                  return [screenTime, batteryLevel, batteryTemperature, batteryStatus, fps];
                }
              }
            }
          },
        }

        if (yAxis[label] !== undefined) {
          options.scales.y.suggestedMin = yAxis[label].suggestedMin;
          options.scales.y.suggestedMax = yAxis[label].suggestedMax;
        } else {
          options.scales.y.ticks = {
            callback: function(value) {
              return timeElapsed(value);
            }
          }
        }
        
        return new Chart(document.getElementById(`canvas-${label}`), {
          type: 'line',
          data: {
            labels: timestamps,
            datasets: [
              {
                data: monitoringData.map(m => m[label]),
                borderColor: lineColor,
                borderWidth: 1
              }
            ]
          },
          options: options
        });
      })

      setCharts(allCharts);
    } catch (err) {
      toaster('Error creating charts.', 2, err);
    }

  }, [monitoringData])

  return (
    <Modal show={true} onHide={() => {
      if (charts) charts.forEach(c => c.destroy());
      handleClose();
    }} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Monitoring charts for {phoneName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {monitoringData === null ? (
          <LoadingElement />
        ) : monitoringData === undefined ? (
          <p>An error occured while fetching the data.</p>
        ) : monitoringData.length === 0 ? (
          <p>There is no data available.</p>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {monitoringDataLabels.map(label => (
              <canvas key={label} id={`canvas-${label}`} width="100%" height="40" />
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default MonitoringModal;